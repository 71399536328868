import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet";

import Layout from "../components/layout"
import Rule from '../components/rule';

const IndexPage = (props) => {
  const { data: { allMarkdownRemark: { nodes } } } = props;

  const announcements = nodes.map(node => (
    <React.Fragment key={node.frontmatter.path}>
      <h3>{node.frontmatter.title}</h3>
      <p>
        {node.excerpt}
        <span className="hide-on-print">{" "}<Link to={`${node.frontmatter.path}#main-body`}>Loe&nbsp;edasi...</Link></span>
      </p>
    </React.Fragment>
  ));

  return (
    <Layout>
      <Helmet>
          <title>SA Mälestus&shy;selts „Artur Sirk“</title>
          <meta property="og:title" content="SA Mälestusselts „Artur Sirk“" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.artursirk.ee/" />
          <meta property="og:description" content="Artur Sirk on olnud Eesti ajaloo üks suurimaid riigimehi, patrioote ja rahva ühendajaid." />
      </Helmet>
      <Rule />
      <div className="o-grid o-grid--small-full o-grid--medium-fit o-grid--large-full">
        <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-100@large" itemScope={true}>
          <h2>Teated</h2>
          {announcements}
        </div>
      </div>
      <Rule />
      <div className="hide-on-print">
        <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-100@large">
          <h2>Loe rohkem...</h2>
        </div>
        <div className="o-grid o-grid--wrap">
          <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-50@medium">
            <h3>
              <Link to="/artur-sirk#main-body">Artur Sirgust</Link>
            </h3>
          </div>
          <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-50@medium">
            <h3>
              <Link to="/selts#main-body">Mälestusseltsist</Link>
            </h3>
          </div>
        </div>
      </div>
      <Rule last />
    </Layout>
  ); 
};

export const pageQuery = graphql`
  {
    allMarkdownRemark {
      nodes {
        excerpt
        frontmatter {
          path
          title
        }
      }
    }
  }
`;

export default IndexPage